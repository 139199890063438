<template>
    <div class="container">
        <!-- <common-head url="/newHome" :title="$t('system.trusteeship')" :is-return="true">
            <div slot="right" class="head_right" @click="routerTo1()">{{ $t('system.chengweilaoshi') }}</div>
        </common-head> -->
		<van-nav-bar
		    :title="$t('system.trusteeship')"
		    left-arrow
		    @click-left="onClickLeft"
			@click-right="routerTo1"
			:right-text="$t('system.chengweilaoshi')"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div class="g_safe_top">
			<div class="nav">
			    <div class="nav_item1">
			        <span class="item" @click="nav_head_select()">{{ $t('system.woyaotuoguan') }}</span>
			        <span class="item" :class="{ active: nav_head == 1 }">{{ $t('system.wodetuoguan') }}</span>
			    </div>
			    <div class="nav_item1" v-if="nav_head == 1">
			        <span class="item" :class="{ active: statusTab == 1 }" @click="status_select(1)">{{ $t('system.zhengzaijinxin') }}</span>
			        <span class="item" :class="{ active: statusTab == 0 }" @click="status_select(0)">{{ $t('system.yijieshu') }}</span>
			    </div>
			</div>
			<div class="content">
			    <div v-if="nav_head == 1">
			        <div v-if="statusTab == 1">
			            <van-list v-model="billLoading1" :finished="billFinished1" :finished-text="$t('cashFlow.NoMore')" :loading-text="$t('common.loading')" @load="onLoadcashFlow1">
			                <van-row class="teacher_list1" v-for="(item, index) in billList1" :key="index" @click="routerTo0(item)" type="flex" justify="space-between">
			                    <van-col span="24">
			                        <div class="list-top">
			                            <div class="msg_left">
			                                <div class="img"><img :src="item.avatar" alt="" /></div>
			                                <div class="name">{{ item.deposit_name }}</div>
			                                <div class="VIP">
			                                    <!-- <div class="img_vip">
			                                        <img src="@/assets/imgs/big_vip.png" alt="" v-if="item.level > 0" />
			                                        <img src="@/assets/imgs/small_vip.png" alt="" v-else />
			                                    </div> -->
			                                    <div class="vip_number" v-if="item.level > 0">VIP{{ item.level }}</div>
			                                    <div class="vip_number" v-else>{{$t('system.putongVIP')}}</div>
			                                </div>
			                                <div class="bili">{{ $t('system.fenchengbili') }}{{ item.brokerage_ratio | fencheng }}%</div>
			                                <div class="bili">{{ item.exchange && item.exchange.toUpperCase() }}-{{ type(item.deposit_type) }}</div>
			                            </div>
			                            <div class="msg_right1">
			                                <div class="top">
			                                    <div class="top_left">
			                                        <div class="left_num">{{ item.escrow_funds }} USDT</div>
			                                        <div class="txt">{{ $t('system.chushituoguanzijin') }}</div>
			                                    </div>
			                                </div>
			                                <div class="bottom1">
			                                    <div class="top_left">
			                                        <div class="left_num">{{ item.profit_amount | baoliu }}</div>
			                                        <div class="txt">{{ $t('system.huodeshouyi') }}</div>
			                                    </div>
			                                    <div class="top_right">
			                                        <div class="left_num">{{ (item.year_rate * 100) | baoliu }}%</div>
			                                        <div class="txt">{{ $t('system.nianhuashouyi') }}</div>
			                                    </div>
			                                </div>
			                                <div class="bottom3">
			                                    <div class="top_left">
			                                        <div class="txt1">{{ $t('system.tuoguanshijian') }}:{{ item.update_at | getTimes(nowTime) }}</div>
			                                    </div>
			                                </div>
			                            </div>
			                        </div>
			                    </van-col>
			                    <van-col span="24">
			                        <div class="bottom_button">
			                            <div class="button">
			                                <div class="stop" @click.stop="show_stop(item)">{{ $t('system.trusteeship_close') }}</div>
			                                <div class="xiu" @click.stop="show_stop1(item)">{{ $t('system.xiugaizijin') }}</div>
			                            </div>
			                        </div>
			                    </van-col>
			                </van-row>
			            </van-list>
			        </div>
			        <div v-if="statusTab == 0">
			            <van-list v-model="billLoading2" :finished="billFinished2" :finished-text="$t('cashFlow.NoMore')" :loading-text="$t('common.loading')" @load="onLoadcashFlow2">
			                <van-row class="teacher_list1" v-for="(item, index) in billList2" :key="index" @click="routerTo(item)" type="flex" justify="space-between">
			                    <van-col span="24">
			                        <div class="list-top">
			                            <div class="msg_left">
			                                <div class="img"><img :src="item.avatar" alt="" /></div>
			                                <div class="name">{{ item.deposit_name }}</div>
			                                <div class="VIP">
			                                    <!-- <div class="img_vip">
			                                        <img src="@/assets/imgs/big_vip.png" alt="" v-if="item.level > 0" />
			                                        <img src="@/assets/imgs/small_vip.png" alt="" v-else />
			                                    </div> -->
			                                    <div class="vip_number" v-if="item.level > 0">VIP{{ item.level }}</div>
			                                    <div class="vip_number" v-else>{{$t('system.putongVIP')}}</div>
			                                </div>
			                                <div class="bili">{{ $t('system.fenchengbili') }}{{ item.brokerage_ratio | fencheng }}%</div>
			                                <div class="bili">{{ item.exchange && item.exchange.toUpperCase() }}-{{ type(item.deposit_type) }}</div>
			                            </div>
			                            <div class="msg_right1">
			                                <div class="top">
			                                    <div class="top_left">
			                                        <div class="left_num">{{ item.total_trusteeship_user }}</div>
			                                        <div class="txt">{{ $t('system.zongdaidanrenshu') }}</div>
			                                    </div>
			                                    <div class="top_right">
			                                        <div class="right_num">{{ item.year_rate.toFixed(2) | fencheng }}%</div>
			                                        <div class="txt">{{ $t('system.nianhuashouyi') }}</div>
			                                    </div>
			                                </div>
			                                <div class="bottom">
			                                    <div class="top_left">
			                                        <div class="left_num">{{ item.todayusdt | baoliu }}</div>
			                                        <div class="txt">{{ $t('system.jinrishouyi') }}</div>
			                                    </div>
			                                    <div class="top_right">
			                                        <div class="left_num">{{ item.totalusdt | baoliu }}</div>
			                                        <div class="txt">{{ $t('system.leijishouyi') }}</div>
			                                    </div>
			                                </div>
			                            </div>
			                        </div>
			                    </van-col>
			                    <van-col span="24">
			                        <div class="bottom_button">
			                            <div class="button">
			                                <div class="left">
			                                    <div class="left-top">{{ item.profit_amount | baoliu }}</div>
			                                    <div class="left-bottom">{{ $t('system.wodeleijishouyi') }}</div>
			                                </div>
			                                <div class="right">
			                                    <div class="left-top">{{ item.trusteeship_times }}</div>
			                                    <div class="left-bottom">{{ $t('system.wodetuoguancishu') }}</div>
			                                </div>
			                            </div>
			                            <div class="button" v-if="item.config_state == 1">
			                                <div class="stop1" @click.stop="start(item)">{{ $t('system.trusteeship') }}</div>
			                                <div class="xiu1" @click.stop="routerTo(item)">{{ $t('system.xiangqing') }}</div>
			                            </div>
			                            <div class="button" v-else>
			                                <div class="xiangqing" @click="routerTo('./my_trusteeshio2')">{{ $t('system.xiangqing') }}</div>
			                            </div>
			                        </div>
			                    </van-col>
			                </van-row>
			            </van-list>
			        </div>
			    </div>
			</div>
			<van-popup v-model="show" round>
			    <div class="tan" v-if="is_stop == 0">
			        <div class="tan_no_txt1">
			            <div class="tan_no_txt1-left">{{ $t('system.chushizijin') }}</div>
			            <div class="tan_no_txt1-right">{{ stop_info.escrow_funds }}USDT</div>
			        </div>
			        <div class="tan_no_txt2">
			            <div class="tan_no_txt1-left">{{ $t('system.hdsy') }}</div>
			            <div class="tan_no_txt1-right">{{ stop_info.profit_amount | baoliu }}USDT</div>
			        </div>
			        <!-- <div class="tan_no_txt3">
			            <div class="tan_no_txt1-left">{{ $t('system.shouyilv') }}</div>
			            <div class="tan_no_txt1-right">{{ ((stop_info.profit_amount / stop_info.escrow_funds) * 100) | baoliu }}%</div>
			        </div> -->
			        <div class="tan_no_button" @click="stop(stop_info.id)">{{ $t('system.trusteeship_close') }}</div>
			        <div class="tishi">*{{ $t('system.ti_tingzhi') }}</div>
			    </div>
			    <div class="tan1" v-if="is_stop == 1">
			        <div class="tan_no_txt1">{{ $t('system.tuoguanzijin') }}</div>
			        <div class="input"><input type="number" :placeholder="$t('system.qingshuru')" v-model="number" /></div>
			        <div class="tan_no_txt2">
			            <div class="left">{{ $t('system.zuidakeyongyue') }}:{{ user_asset }}USDT</div>
			            <div class="right" @click="quanbutuoguan()">{{ $t('system.quanbutuoguan') }}</div>
			        </div>
			        <div class="tan_no_button" @click="xiu(stop_info.id)">{{ $t('system.querenxiugai') }}</div>
			    </div>
			    <div class="tan1" v-if="is_stop == 3">
			        <div class="tan_no_txt1">{{ $t('system.tuoguanzijin') }}</div>
			        <div class="input"><input type="number" :placeholder="$t('system.qingshuru')" v-model="number1" /></div>
			        <div class="tan_no_txt2">
			            <div class="left">{{ $t('system.zuidakeyongyue') }}:{{ user_asset }}USDT</div>
			            <div class="right" @click="quanbutuoguan1()">{{ $t('system.quanbutuoguan') }}</div>
			        </div>
			        <div class="tan_no_button" @click="tuo1(stop_info.id)">{{ $t('system.querentuoguan') }}</div>
			    </div>
			</van-popup>
		</div>
        
    </div>
</template>

<script>
// import TitleTwo from "../../components/titleTwo";
import commonHead from '../../components/commonHead';
export default {
    components: { commonHead },
    data() {
        return {
            list: [],
            nav_head: 1,
            statusTab: 1,
            show: false,
            is_stop: 0,
            number: null,
            number1: null,
            state: 0,
            stop_info: {},
            billLoading1: false,
            billFinished1: false,
            billLoading2: false,
            billFinished2: false,
            billData1: [],
            billList1: [],
            billPage1: 1,
            billData2: [],
            billList2: [],
            user_asset: '',
            nowTime: 0
        };
    },
    created() {
        // this.getBalance();
        setInterval(() => {
            this.nowTime = new Date().getTime();
        }, 1000);
        console.log(this.$route.query);
        if(JSON.stringify(this.$route.query) !='{}') {
            this.statusTab = this.$route.query.query
        }
    },
    filters: {
        fencheng(v) {
            return v * 100;
        },
        baoliu(v) {
            return v ? parseFloat(v).toFixed(2) : '0.00';
        },
        getTimes(val, nowTime) {
            let sm = parseInt((nowTime - new Date(val).getTime()) / 1000);

            let s = sm % 60;
            let m = parseInt(sm / 60);
            if (s < 10) {
                s = '0' + s;
            }
            if (m >= 60) {
                let h = parseInt(m / 60);
                m = m % 60;
                if (h >= 24) {
                    let d = parseInt(h / 24);
                    h = h % 24;
                    return d + '天 ' + h + ':' + m + ':' + s;
                }
                if (h < 10) {
                    h = '0' + h;
                }
                return h + ':' + m + ':' + s;
            }
            if (m < 10) {
                m = '0' + m;
            }
            return '00:' + m + ':' + s;
        }
    },
    computed: {
       
    },
    methods: {
		onClickLeft() {
			this.$router.back(-1);
		},
		type(v) {
		    let a = '';
		    if (v == 1) {
		        a = this.$t('system.xianhuojiaoyi');
		    } else if (v == 2) {
		        a = this.$t('system.heyuejiaoyi');
		    }
		    return a;
		},
        nav_head_select() {
            this.$router.push('/new_trusteeship')
        },
        status_select(e) {
            this.statusTab = e;
            //          this.billData1 = [];
            // this.billList1 = [];
            //          this.billData2 = [];
            // this.billList2 = [];
            //          this.billPage1 = 1

            if (this.statusTab == 1) {
                this.billList1 = [];
                this.billPage1 = 1;
                this.billLoading1 = false;
                this.billFinished1 = false;
                // this.onLoadcashFlow1()
            } else if (this.statusTab == 0) {
                this.billList2 = [];
                this.billPage1 = 1;
                this.billLoading2 = false;
                this.billFinished2 = false;
            }
        },
        quanbutuoguan() {
            this.number = this.user_asset;
        },
        quanbutuoguan1() {
            this.number1 = this.user_asset;
        },
        routerTo0(e) {
            this.$router.push({
                path: '/my_trusteeshio_msg',
                query: {
                    config_id: e.config_id
                }
            });
        },
        routerTo(e) {
            this.$router.push({
                path: '/my_trusteeshio2',
                query: {
                    config_id: e.config_id,
                    msg: e
                }
            });
        },
        routerTo1() {
            this.$router.push({
                path: '/im_teacher',
                query: {}
            });
        },
        show_stop(e) {
            this.is_stop = 0;
            this.stop_info = e;
            this.show = !this.show;
        },
        show_stop1(e) {
            this.is_stop = 1;
            this.stop_info = e;
            this.getBalance();
            this.show = !this.show;
        },
        stop(e) {
            this.show = !this.show;
            if (this.stop_info.state != '关闭') {
                this.state = 0;
                this.stopOrStartTrusteeshipUser(e);
            }
        },
        start(e) {
            this.show = !this.show;
            this.is_stop = 3;
            this.stop_info = e;
            this.getBalance();
        },
        //修改托管资金按钮
        xiu(e) {
            this.show = !this.show;
            this.modifyEscrowFundsTrusteeshipUser(e);
        },
        tuo1(e) {
            this.show = !this.show;
            if (this.stop_info.state == '关闭') {
                this.state = 1;
                this.stopOrStartTrusteeshipUser(e);
            }
        },
        // 获取-某一个-交易所的可用余额-传参-(交易所exchange=>1:huobi/2:bian/3:okex/4:uniswap)
        getBalance() {
            this.$post2('Robot/Api/Index/getBalance', {
                exchange: this.stop_info.exchange,
                noToast: true
            })
                .then(res => {
                    if (res.USDT.amount !== null) {
                        this.user_asset = res.USDT.amount;
                    }
                })
                .catch(e => {
                    // console.log("e222: ",e);
                    //     this.$toast.clear()
                });
        },
        //用户我的托管列表
        getTrusteeshipUserList() {
            this.$post2('Deposit/Api/Index/getTrusteeshipUserList', {
                state: this.statusTab,
                page: this.billPage1++
            })
                .then(res => {
                    // console.log(res);
                    if (this.statusTab == 1) {
                        this.billData1 = res.list;
						for (let i = 0; i < this.billData1.length; i++) {
							this.billList1.push(this.billData1[i]);
						}
						// console.log(this.shouyiList);
						// 加载状态结束
						this.billLoading1 = false;
						// 数据全部加载完成
					
						// console.log(this.billData1);
						if (this.billData1.length < 1) {
							this.billFinished1 = true;
						}
                    } else if (this.statusTab == 0) {
                        this.billData2 = res.list;
						for (let i = 0; i < this.billData2.length; i++) {
							this.billList2.push(this.billData2[i]);
						}
						// console.log(this.shouyiList);
						// 加载状态结束
						this.billLoading2 = false;
						// 数据全部加载完成
					
						// console.log(this.billData2);
						if (this.billData2.length < 1) {
							this.billFinished2 = true;
						}
                    }
                })
                .catch(e => {
                    this.$toast.fail(e);
                });
        },
        //用户修改托管资金
        modifyEscrowFundsTrusteeshipUser(e) {
            this.$post2('Deposit/Api/Index/modifyEscrowFundsTrusteeshipUser', {
                escrow_funds: this.number,
                id: e
            })
                .then(res => {
                    // console.log(res);
                    this.$toast('修改成功');
                    this.billLoading1 = true;
                    this.billFinished1 = false;
                    this.billData1 = [];
                    this.billList1 = [];
                    this.billPage1 = 1;
                    this.onLoadcashFlow1();
                })
                .catch(e => {
                    this.$toast.fail(e);
                });
        },
        //用户暂停或者开启自己购买的某个托管套餐
        stopOrStartTrusteeshipUser(e) {
            this.$post2('Deposit/Api/Index/stopOrStartTrusteeshipUser', {
                state: this.state, //0 1
                id: e,
                escrow_funds: this.number1
            })
                .then(res => {
                    // console.log(res);
                    if (this.state == 0) {
                        this.$toast('暂停托管成功');
                        this.billLoading1 = true;
                        this.billFinished1 = false;
                        this.billData1 = [];
                        this.billList1 = [];
                        this.billPage1 = 1;
                        this.onLoadcashFlow1();
                    } else if (this.state == 1) {
                        this.$toast('开启托管成功');
                        this.billLoading2 = true;
                        this.billFinished2 = false;
                        this.billData2 = [];
                        this.billList2 = [];
                        this.billPage1 = 1;
                        this.onLoadcashFlow2();
                    }
                })
                .catch(e => {
                    this.$toast.fail(e);
                });
        },
        onLoadcashFlow1() {
            this.getTrusteeshipUserList();
            // 异步充值记录
            
        },
        onLoadcashFlow2() {
            this.getTrusteeshipUserList();
            // 异步充值记录
            
        }
    }
};
</script>

<style scoped lang="less">
.container {
    background-color: #F7F7FA;
    height: 100%;
	font-family: PingFangSC-Regular;
    // height: calc(100vh + 110px);
    .content {
        background-color: #F7F7FA;
        padding-bottom: 1rem;
    }
    .head_right {
        color: #333333;
        font-size: 14px;
    }
}
.nav {
    margin-top: 0.2rem;
    border-top-left-radius: 0.32rem;
    border-top-right-radius: 0.32rem;
    background-color: #ffffff;
    font-family: PingFang SC;
	padding: 0.15rem 0;
    .nav_item1 {
        font-size: 14px;
        padding: 0.15rem 33px;
        color: #8F9EAB;
        // border-bottom: 1px solid #f1f1f1;
        .item {
            margin-left: 30px;
        }
        .item:first-child {
            margin-left: 0;
        }
    }
    .nav_item2 {
        font-size: 14px;
        padding: 0.15rem 33px;
        color: #8F9EAB;
		display: flex;
		justify-content: space-between;
        // border-bottom: 1px solid #f1f1f1;
        .item {
            // margin-left: 20px;
            text-align: center;
        }
        .item:first-child {
            margin-left: 0;
        }
    }
    .nav_item3 {
        font-size: 14px;
        padding: 0.15rem 33px;
        color: #8F9EAB;
        // border-bottom: 1px solid #f1f1f1;
        .item {
            margin-left: 30px;
            text-align: center;
        }
        .item:first-child {
            margin-left: 0;
        }
    }
    .nav_item4 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        padding: 0.15rem 34px;
        color: #8F9EAB;
        .item4_1 {
            border-radius: 4px;
            background-color: #F7F7FA;
            padding: 6px 5px 6px 5px;
            text-align: center;
        }
        .item4_2 {
            border-radius: 4px;
            background-color: #F7F7FA;
            padding: 6px 5px 6px 5px;
            text-align: center;
        }
        .item4_3 {
            border-radius: 4px;
            background-color: #F7F7FA;
            padding: 6px 5px 6px 5px;
            text-align: center;
        }
        .active1 {
            color: #333;
            font-weight: 600;
        }
    }
    .active {
        color: #333333;
        font-size: 14px;
        font-weight: 600;
    }
}
.content {
    .teacher_list1 {
        background-color: #ffffff;
        margin-top: 10px;
        // box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
        font-family: PingFang SC;
		border-top-left-radius: 0.32rem;
		border-top-right-radius: 0.32rem;
		padding: 0.12rem 0.2rem;
        .list-top {
            display: flex;
            // margin-left: 0.7rem;
        }
        .msg_left {
            margin: 10px 0;
            // margin-left: 0.8rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            .img {
                width: 1.2rem;
                height: 1.2rem;
                img {
                    border-radius: 0.5rem;
                    width: 100%;
                    height: 100%;
                }
            }
            .name {
				margin-top: 0.12rem;
                font-size: 14px;
                font-weight: 500;
                color: #333333;
                text-align: center;
            }
            .VIP {
                display: flex;
                align-items: center;
                .img_vip {
                    // width: 0.24rem;
                    // height: 0.24rem;
                    img {
                        width: 0.252399rem;
                        height: 0.2218rem;
                    }
                }
                .vip_number {
					font-family: YouSheBiaoTiHei;
                    margin-left: 0.05rem;
                    font-size: 0.28rem;
                    color: #EFBA0D;
                }
            }
            .bili {
                margin-top: 0.1rem;
                background-color: #FFFAE8;
                padding: 0.08rem 0.28rem 0.06rem 0.28rem;
                border-radius: 0.08rem;
                font-size: 0.24rem;
				transform: scale(0.91);
                color: #EFBA0D;
                text-align: center;
				font-weight: 600;
            }
        }
        .msg_right1 {
            margin-top: 0.87rem;
            margin-left: 0.8rem;
            .top {
                display: flex;
                align-items: center;
                .top_left {
                    text-align: left;
                }
                .top_right {
                    margin-left: 0.9rem;
                    text-align: left;
                }
                .txt {
                    text-align: left;
                    color: #6F7A8B;
                    font-size: 0.24rem;
                }
                .left_num {
                    color: #333333;
                    font-size: 0.32rem;
					font-weight: 600;
                }
                .right_num {
                    color: #32BF88;
                    font-size: 0.32rem;
					font-weight: 600;
                }
            }
            .bottom {
                margin-top: 0.4rem;
                display: flex;
                align-items: center;
                // justify-content: space-between;
                .top_left {
                    text-align: left;
                }
                .top_right {
                    text-align: left;
					margin-left: 0.57rem;
                }
                .txt {
                    text-align: left;
                    margin-left: -0.09rem;
                    color: #666666;
                    font-size: 0.24rem;
                    transform: scale(0.83);
                }
                .txt1 {
                    margin-top: 0.15rem;
                    text-align: left;
                    color: #666666;
                    font-size: 0.24rem;
                }
                .left_num {
                    color: #333333;
                    font-size: 0.32rem;
                }
                .right_num {
                    color: #333333;
                    font-size: 0.32rem;
                }
            }
            .bottom1 {
                margin-top: 0.4rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .top_left {
                    text-align: left;
                }
                .top_right {
                    margin-left: 0.6rem;
                    text-align: left;
                }
                .txt {
                    text-align: left;
                    color: #6F7A8B;
                    font-size: 0.24rem;
                }
                .txt1 {
                    margin-top: 0.15rem;
                    text-align: left;
                    color: #666666;
                    font-size: 0.24rem;
                }
                .left_num {
                    color: #333333;
                    font-size: 0.32rem;
                }
                .right_num {
                    color: #333333;
                    font-size: 0.32rem;
                }
            }
            .bottom3 {
                margin-top: 0.4rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .top_left {
                    text-align: left;
                }
                .top_right {
                    text-align: left;
                }
                .txt {
                    text-align: left;
                    color: #EFBA0D;
                    font-size: 0.24rem;
                }
                .txt1 {
                    margin-top: 0.15rem;
                    text-align: left;
                    color: #EFBA0D;
                    font-size: 0.24rem;
                }
                .left_num {
                    color: #5998fc;
                    font-size: 0.3rem;
                }
                .right_num {
                    color: #d98181;
                    font-size: 0.3rem;
                }
            }
        }
        .bottom_button {
            // margin: 0 0.62rem;
            margin-top: 0.2rem;
            padding: 0.32rem 0;
            border-top: 1px solid #cbcbcb;
            .button {
                display: flex;
                justify-content: space-between;
                .stop {
                    width: 3.26rem;
                    height: 0.8rem;
                    text-align: center;
                    line-height: 0.8rem;
                    background-color: #FAD538;
                    color: #333333;
                    font-size: 0.32rem;
                    text-align: center;
                    font-weight: 500;
                    // padding: 0.18rem 0.86rem;
                    border-radius: 0.08rem;
                }
                .xiu {
                    width: 3.26rem;
                    height: 0.8rem;
                    text-align: center;
                    line-height: 0.8rem;
                    background-color: #FAD538;
                    color: #333333;
                    font-size: 0.32rem;
                    text-align: center;
                    font-weight: 500;
                    // padding: 0.18rem 0.86rem;
                    border-radius: 0.08rem;
                }
                .stop1 {
                    width: 3.26rem;
                    height: 0.8rem;
                    text-align: center;
                    line-height: 0.8rem;
                    background-color: #FAD538;
                    color: #333333;
                    font-size: 0.32rem;
                    text-align: center;
                    font-weight: 500;
                    // padding: 0.18rem 0.86rem;
                    border-radius: 0.08rem;
                }
                .xiu1 {
                   width: 3.26rem;
                   height: 0.8rem;
                   text-align: center;
                   line-height: 0.8rem;
                   background-color: #FAD538;
                   color: #333333;
                   font-size: 0.32rem;
                   text-align: center;
                   font-weight: 500;
                   // padding: 0.18rem 0.86rem;
                   border-radius: 0.08rem;
                }
                .xiangqing {
                    width: 3.26rem;
                    height: 0.8rem;
                    text-align: center;
                    line-height: 0.8rem;
                    background-color: #FAD538;
                    color: #333333;
                    font-size: 0.32rem;
                    text-align: center;
                    font-weight: 500;
                    // padding: 0.18rem 0.86rem;
                    border-radius: 0.08rem;
                }
                .left {
                    width: 50%;
                    text-align: center;
                    margin-bottom: 0.38rem;
                    .left-top {
                        color: #333333;
                        font-size: 0.32rem;
                        font-weight: 600;
                    }
                    .left-bottom {
                        color: #6F7A8B;
                        font-size: 0.24rem;
                    }
                }
                .right {
                    width: 50%;
                    text-align: center;
                    margin-bottom: 0.38rem;
                    .left-top {
                        color: #333333;
                        font-size: 0.32rem;
						font-weight: 600;
                    }
                    .left-bottom {
                        color: #6F7A8B;
                        font-size: 0.24rem;
                    }
                }
            }
        }
    }
}
.tan {
    width: 5.6rem;
    height: 3.4rem;
    // background-image: url(../../assets/imgs/tan_bg.png);
    // background-repeat: no-repeat;
    // background-size: 100% 3.6rem;
    .tan_no_txt {
        padding: 0.69rem 0.82rem 0 0.6rem;
        color: #333333;
        font-size: 0.32rem;
        text-align: center;
        font-weight: 500;
    }
    .tan_no_txt1 {
        padding: 0 1.2rem;
		padding-top: 0.63rem;
        color: #333333;
        text-align: left;
        font-weight: 500;
        color: #333333;
		display: flex;
		align-items: center;
		.tan_no_txt1-left {
			color: #333333;
			font-size: 0.32rem;
		}
		.tan_no_txt1-right {
			color: #333333;
			font-size: 0.32rem;
			margin-left: 0.3rem;
		}
    }
    .tan_no_txt2 {
        margin-top: 0.05rem;
		padding: 0 1.2rem;
        color: #333333;
        font-weight: 500;
        text-align: left;
		display: flex;
		align-items: center;
        .tan_no_txt1-left {
        	color: #333333;
        	font-size: 0.32rem;
        }
        .tan_no_txt1-right {
        	color: #333333;
        	font-size: 0.32rem;
        	margin-left: 0.3rem;
        }
    }
    .input {
		margin-top: 0.08rem;
        padding: 0 0.7rem 0 0.72rem;
        color: #333333;
        font-weight: bold;
        input {
            width: 100%;
            border: none;
            border-bottom: 1px solid #D3D9DE;
            padding: 0.04rem 0;
            font-size: 0.32rem;
        }
    }
    .tan_no_button {
		width: 2.62rem;
		height: 0.66rem;
		line-height: 0.66rem;
		text-align: center;
        // margin: 0.47rem 1.2rem 0 1.32rem;
        font-size: 0.28rem;
        color: #FFFFFF;
        background-color: #FAD538;
        border-radius: 0.08rem;
		margin: 0 auto;
		margin-top: 0.45rem;
    }
	.tishi {
		text-align: center;
		color: #F5465D;
		font-size: 0.24rem;
		transform: scale(0.666);
	}
}
.tan1 {
    width: 5.6rem;
    height: 3.4rem;
    // background-image: url(../../assets/imgs/tan_bg.png);
    // background-repeat: no-repeat;
    // background-size: 100% 3.6rem;
    .tan_no_txt {
        padding: 0.69rem 0.82rem 0 0.6rem;
        color: #333333;
        font-size: 0.32rem;
        text-align: center;
        font-weight: 500;
    }
    .tan_no_txt1 {
        padding: 0.69rem 0.82rem 0 0.72rem;
        color: #333333;
        font-size: 0.24rem;
        text-align: left;
        font-weight: 500;
        color: #333333;
    }
    .tan_no_txt2 {
        margin-top: 0.05rem;
        color: #333333;
        font-size: 0.16rem;
        transform: scale(0.75);
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        text-align: left;
        .right {
            color: #F5465D;
        }
    }
    .input {
		margin-top: 0.08rem;
        padding: 0 0.7rem 0 0.72rem;
        color: #333333;
        font-weight: bold;
        input {
            width: 100%;
            border: none;
            border-bottom: 1px solid #D3D9DE;
            padding: 0.04rem 0;
            font-size: 0.32rem;
        }
    }
    .tan_no_button {
        margin: 0.47rem 1.2rem 0 1.32rem;
        padding: 0.1rem 0.36rem 0.08rem 0.36rem;
        font-size: 0.28rem;
        color: #FFFFFF;
        background-color: #FAD538;
        text-align: center;
        border-radius: 0.08rem;
    }
}
</style>
